import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Head from 'next/head';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '92vh',
    width: '100%',
    overflow: 'hidden',
  },
  img: {
    opacity: 0.65,
    width: '100%',
    height: '100%',
  },
}));
const Index = () => {
  const classes = useStyles();
  return (
    <>
      <Head>
        <title>CashFlow</title>
      </Head>
      <Box className={classes.root}>
        <img src="/dashboard-background.webp" alt="img" className={classes.img} />
      </Box>
    </>
  );
};

export default Index;
